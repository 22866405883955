import React from 'react';
import { Flex, Image } from '@chakra-ui/core';

import Layout from '../components/layout';
import ContentContainer from '../components/ContentContainer';
import { APP_CONTENT_WIDE_WIDTH, APP_CONTENT_DESKTOP_WIDTH } from '../styles/sizes';
import { colors } from '@theme';
import { AdditionalOwnerTopSection, Metadata } from '../components/additionalInvite';
import CardFeatures from '../components/jointAccount/cardFeatures';
import FAQs from '../components/jointAccount/FAQs';
import Anchor from '../components/jointAccount/anchor';
import { cloudfrontBaseUrl, JAFAQContent, JAHelpfulResources } from '../lib/consts';
import { H2, H4 } from '@typography';
import ContentResource from '../components/home/ContentResource';
import { DesktopOnly, MobileOnly } from '../layouts/Footer';
import VIP from '../components/jointAccount/VIP';
import { FamiliesHeartZeta, KeyFeatures } from '../components/familyAccount/index';

const AdditionalInvite = props => {
  return (
    <Layout mode="light" newNav noRootContainer noNav>
      <Metadata />
      <Flex
        className="main-column"
        width="100%"
        justifyContent="center"
        direction="column"
        pt={[0, 0]}
      >
        <AdditionalOwnerTopSection />

        <Flex mb={['50px', '82px']} justify="center">
          <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
            <KeyFeatures />
          </ContentContainer>
        </Flex>

        <VIP />

        <Flex bg={colors.transparent} className="theres-even-more-to-love">
          <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
            <CardFeatures />
          </ContentContainer>
        </Flex>

        <FamiliesHeartZeta />

        <Flex bg={colors.background} className="FAQs">
          <FAQs content={JAFAQContent} />
        </Flex>

        <Flex bg={colors.white} className="helpful-resources">
          <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
            <Flex py={[10, 24]} direction="column" justify="center" align="center">
              <Flex
                justify="center"
                align="center"
                direction="column"
                textAlign="center"
                mb={[10, 16]}
                px={[4, 0]}
              >
                <H2>More helpful resources</H2>
                <DesktopOnly>
                  <H4 mt={4} center>
                    Some useful information about how joint bank accounts work:
                  </H4>
                </DesktopOnly>
                <MobileOnly>
                  <H4 mt={4} center>
                    Some useful information about how
                    <br />
                    joint bank accounts work:
                  </H4>
                </MobileOnly>
              </Flex>

              <Flex
                pt={[6, '36px']}
                direction={['column', 'row']}
                width={['100%', '90%']}
                justify="space-between"
              >
                {JAHelpfulResources.map(({ title, image, description, ctaUrl }) => (
                  <ContentResource
                    key={`cr-${title}`}
                    titleBreak
                    customTitleSize="24px"
                    title={title}
                    description={description}
                    imageSource={image}
                    ctaUrl={ctaUrl}
                  />
                ))}
              </Flex>
            </Flex>
          </ContentContainer>
        </Flex>

        <Anchor />
      </Flex>
    </Layout>
  );
};

export default AdditionalInvite;
