import React, { useEffect, useState } from 'react';
import { Flex, Text, Image, Box, useMediaQuery } from '@chakra-ui/core';
import querystring from 'query-string';
import { Button } from '@zeta';
import { colors } from '@theme';
import Lottie from 'react-lottie';
import { H4 } from '@typography';
import ContentContainer from '../ContentContainer';
import { getPendingInvitation, nextUrl } from '../../lib/invitations/utils';

import { DesktopOnly, MobileOnly } from '../../layouts/Footer';

import JointAccountAnimation from '../../lotties/joint-1-animation.json';
import JointAccountMobileAnimation from '../../lotties/joint-account-mobile-animation.json';

import ZetaLogoLightmode from '@assets/ZetaLogoLightmode.svg';
import {
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
  APP_CONTENT_MOBILE_WIDTH,
  APP_CONTENT_DESKTOP_WIDTH,
} from '../../styles/sizes';
import { cloudfrontBaseUrl } from '../../lib/consts';

const toSentence = (words, { andWord = ' and' } = {}) => {
  if (!words || !Array.isArray(words)) {
    return '';
  }
  const lastWord = words.slice(-1)[0];
  const priorWords = words.slice(0, -1);

  if (priorWords.length === 0) {
    return lastWord;
  }
  return `${priorWords.join(', ')}${andWord} ${lastWord}`;
};

const TopSection = () => {
  const [invite, setInvite] = useState({});
  const [isMobile] = useMediaQuery(`(max-width: ${APP_CONTENT_MOBILE_WIDTH})`);

  const FamilyHeroLeft = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-hero-left.png`;
  const FamilyHeroRight = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-hero-right.png`;

  let search = '';
  if (typeof window !== 'undefined') {
    search = window.location.search;
  } else {
    search = '';
  }

  const parsed = search ? querystring.parse(search) : {};
  const { token } = parsed;

  const getInvite = async () => {
    const i = await getPendingInvitation({ token });
    setInvite(i);
    return true;
  };

  useEffect(() => {
    if (token?.length) {
      getInvite();
    }
  }, []);

  const names = invite?.accountMembers?.map(m => m.firstName);
  const claimUrl = `${nextUrl}/welcome/additional?token=${token}`;

  return (
    <Flex
      align="center"
      direction="column"
      background={'linear-gradient(to bottom, #FDF3F3 0%, #FDF3F3 20%, #fff 100%)'}
      pt={12}
    >
      <Flex
        background={colors.transparent}
        marginBottom={['1rem', '1rem']}
        justify={['center', 'center', 'flex-start', 'flex-start']}
        width={'100%'}
        paddingLeft={[0, 0, 6, 6]}
        maxWidth={APP_CONTENT_DESKTOP_WIDTH}
      >
        <Image src={ZetaLogoLightmode} height="36px" />
      </Flex>
      <ContentContainer minWidth={'auto'} width={'100%'}>
        <Flex align="center" direction="column" pt={[8, 4]}>
          <Flex align="center" direction="column" paddingX={[6, 0]}>
            {names?.length ? (
              <Text
                as="h1"
                textAlign="center"
                fontSize={['24px', '32px']}
                fontWeight="600"
              >
                {toSentence(names)} want to
                {isMobile ? ' ' : <br />}
                add you to their Zeta Account
              </Text>
            ) : null}
            <H4 as="h2" mt={8} textAlign={'center'}>
              Shared banking made easy
            </H4>
          </Flex>
          <Flex justify={['center', 'center']} mt={16}>
            <Button
              style={{
                height: '40px',
                width: '121px',
              }}
              text="See invite"
              variant="transparent"
              onClick={() => {
                window.location.href = claimUrl;
              }}
            />
          </Flex>
          <Flex paddingTop={[16, 16, 24, 24]} justifyContent={'center'}>
            <Flex direction={'column'}>
              <Flex
                maxWidth={APP_CONTENT_SMALL_DESKTOP_WIDTH}
                style={{ gap: 12 }}
                width={'100%'}
                direction={['column', 'column', 'row', 'row']}
                alignItems={['center', 'center', 'inherit', 'inherit']}
              >
                <Box
                  width={['50%', '50%', '30%', '30%']}
                  display={'flex'}
                  alignItems={'center'}
                  alignSelf={['flex-start', 'flex-start', 'flex-start', 'flex-start']}
                  marginLeft={['-12px', '-12px', 0, 0]}
                >
                  <Image width="100%" src={FamilyHeroLeft} paddingTop={[0, 0, 12, 12]} />
                </Box>
                <Box width={['70%', '70%', '40%', '40%']}>
                  <Lottie
                    isClickToPauseDisabled
                    style={{
                      cursor: 'auto',
                    }}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: isMobile
                        ? JointAccountAnimation
                        : JointAccountAnimation,
                    }}
                  />
                </Box>
                <Box
                  width={['50%', '50%', '30%', '30%']}
                  display={'flex'}
                  alignItems={'flex-start'}
                  alignSelf={['flex-end', 'flex-end', 'flex-end', 'flex-end']}
                  marginRight={['-12px', '-12px', 0, 0]}
                >
                  <Image
                    width="100%"
                    paddingBottom={[0, 0, 24, 24]}
                    src={FamilyHeroRight}
                  />
                </Box>
              </Flex>
              <Flex align="center" marginBottom="81px" marginTop="12px" justify="center">
                <Text
                  ml={4}
                  fontStyle="italic"
                  width="230px"
                  fontSize={'10px'}
                  style={{ textAlign: 'center' }}
                >
                  Zeta Accounts are deposit accounts powered by Piermont Bank, Member
                  FDIC.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default TopSection;
